.loginPage {
  background-color: #000000;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-justify: inter-word;
}

.loginPage h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  font-size: 35.3906px;
}

.loginPage p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 21.2344px;
  color: #808080;
}

.btn {
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  height: 48px;
}

.exploreBtn {
  background: rgba(255, 255, 255, 0.05);
  border: 1.17969px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 25%;
  margin-bottom: 20px;
}
.exploreLoginBtn {
  background: #0129fa;
  width: 25%;
  border-radius: 4px;
  border: none;
}

.exploreLogo {
  width: 13%;
}

.exploreLogoGif {
  position: relative;
  animation: mymove 5s infinite;
  width: 13%;
}

@keyframes mymove {
  from {
    bottom: 0px;
  }
  to {
    bottom: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .exploreLogo {
    width: 40%;
  }

  .exploreLogoGif {
    position: relative;
    animation: mymove 5s infinite;
    width: 40%;
  }
  .loginPage h2 {
    font-size: 28px;
  }
  .loginPage p {
    font-size: 20px;
  }
  .exploreBtn {
    width: 70%;
  }
  .exploreLoginBtn {
    width: 70%;
  }
}
