/* schdule in progress = sip  */
.sip_team_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}

.sip_teams_bg {
  background-color: rgba(244, 244, 244, 0.5) !important;
}

.scheduleCardHeight {
  min-height: 70%;
}

.sip_invited_mem {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(1, 41, 250, 0.1);
  border-radius: 60px;
}

.sip_invit_mem {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0129fa;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sip_other_invites {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
}

.sip_intvies_input {
  height: 62px;
  background: rgba(202, 202, 202, 0.5);
  opacity: 0.6;
  border-radius: 4px;
}

.sip_invites_addmore {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0129fa;
}

.sip_invity {
  background: rgba(1, 41, 250, 0.1);
  border-radius: 4px;
}

.inputRadioGroup1 {
  display: flex;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 4px;
}

.inputRadioGroup1 input[type="checkbox"]:checked + label {
  border: 1px solid #f05f23;

  background-image: url("./../../../../../images/selectedMembers.svg");
  /* background: url("../../../../../images/selectedMembers.svg") no-repeat 80% 50%; */
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
  /* background-color: rgba(1, 41, 250, 0.1);
  color: #000000; */
}

input[type="checkbox"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
  background-color: none;
}
