/* request Sent = rs */

.rs_centerized {
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.rs_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  color: #000000;
}
.rs_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}
.rs_img_size {
  margin: auto;
  height: 55.73px;
  width: 129px;
  align-items: center;
  float: center !important;
  text-align: center !important;
}
