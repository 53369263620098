.lgLogoContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.lgLogo {
  width: 99px;
}

.loginRow {
  height: 90vh;
}
.loginImageCol {
  display: flex;
  align-items: flex-end;
}

.loginImage {
  width: 100%;
}

.lgInput {
  height: 50px;
  /* margin-top: 5px; */
  width: 100%;
  margin-bottom: 18px;
  border-radius: 4px;
  /* background: rgba(202, 202, 202, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.loginForm {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.loginForm h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.loginForm p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}
.loginForm h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.update-input-icons {
  width: 400px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.update-input-icons i {
  position: absolute;
}

.eye1 {
  padding: 12px;
  margin-right: 2px;
  margin-top: 2px;
  width: 45px;
  height: 44px;
  background: rgb(255, 255, 255);
}

.marginTopRedir {
  /* margin-top: 250px;
  height: 50px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center; */

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputField {
  width: 100%;
  height: 48px;
  border: 1px solid #cacaca;
  border-radius: 4px;
  margin-bottom: 20px;
}

.loginBtn {
  height: 52px;
  background: #000000;
  border-radius: 4px;
}

.forgotAncher {
  color: #000000;
  --bs-link-hover-color: #000000;
}

.textcolorolink {
  color: #000000 !important;
}

.ftCss {
  position: fixed;
  bottom: 0;
  z-index: -10;
  width: 100%;
  background-color: black;
  height: 50px;
  align-items: center;
}

.ftCss1 {
  /* margin-top: 10px; */
  font-family: "Montserrat";
  font-weight: 400;

  font-size: 14px;
  color: white;
  text-align: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .loginImageCol {
    display: none;
  }

  .loginForm {
    padding-left: 30px;
    padding-right: 30px;
  }
}
