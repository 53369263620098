/* dashboard = db */

.db_card {
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin-bottom: 20px;
}

.db_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.db_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Official Black */

  color: #333333;
}

.db_card_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #000000;
}

.db_card_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* New grey description */

  color: #808080;
}

.link {
  text-decoration-line: none;
}
