.inputerror {
  color: #ef4050;
  font-size: 12px;
  padding: 3px;
  display: none;
}
.formInput {
  padding: 1px;
}
.forminputsize {
  height: 56px;
  /* border: 1px solid #000000; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.inputs:invalid[focused="true"] + .inputerror {
  display: block;
}

.inputs:invalid[focused="true"] {
  border: 1px solid red;
}
/* invalid[focused="ture"] */

.modelImg {
  display: flex;
  justify-content: center;
}

.modelBody {
  text-align: center;
}

.modelBtn1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5) !important;
}

.modelBtn2 {
  background: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  border: none;
  height: 48px;
}

.model-body {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.model-body2 {
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.modelDesp {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* New grey description */

  color: #808080;
}

.modelHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}

.medelDelAccount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  align-items: center;
  text-align: center;

  /* New grey description */

  color: #808080;
}

.helpDeskModel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  /* display: flex; */
  align-items: left;
  text-align: left;

  color: #000000;
}

.helpDeskModelDate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: left;
  text-align: left;
  color: #828282;
}

.helpDeskModelbody {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: left;
  text-align: left;

  color: #999999;
}

.helpDeskModelattachments {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.helpDeskModelphoto {
  align-items: left;
  text-align: left;
  width: 167px;
  height: 146px;
}

.helpDeskModelResolved {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #35d7a1;

  background: rgba(53, 215, 161, 0.1);
  border-radius: 2px;
}

.helpDeskModelInreview {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #f99b1c;

  background: rgba(249, 155, 28, 0.1);
  border-radius: 2px;
}
