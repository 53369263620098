.maincontent {
  background-color: #f7f7f8;
}
.maincontainercss {
  background: #f7f7f8;
  /* padding: 0 0 50px 0; */
  /* margin-top: 80px; */
  min-height: 100vh;
}

/* .col-header {
  width: 80%;
} */
.header-row {
  position: sticky;
  top: 0;
  z-index: 1;
  /* width: 100%; */
}
