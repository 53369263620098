.btn_color {
  color: black;
}

.edit_btn_participent {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  padding-top: 7px;
}

.edit_btn_justify {
  display: flex;
  justify-content: center;
  align-items: center !important;
}
