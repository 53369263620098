.maincontent {
  background-color: #f7f7f8;
}

.col-header {
  width: 80%;
}
.header-row {
  position: sticky;
  top: 0;
  /* width: 100%; */
  z-index: 1;
  /* box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%); */
}

.feedCards {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: none;
  box-shadow: "0px 4px 10px rgba(0, 0, 0, 0.1)";
}
.feedCards h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}
.feedCards p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
}

.feedCards span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
}

.feedImgs {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  border-radius: 6px;
}
