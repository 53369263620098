/* privateOfficeSummary = pos */

.pos_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  display: flex;
  align-items: center;

  color: #000000;
}

.pos_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height */
  display: flex;
  color: rgba(0, 0, 0, 0.5);
}

.pos_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.pos_h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  color: #000000;
}

.pos_h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  color: #0129fa;
}

.pos_display_inline {
  display: flex;
  justify-content: space-between;
}
.pos_display_inline1 {
  display: flex;
}

.pos_cardusersicon {
  justify-content: space-between;
}
.pos_total_users {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
  opacity: 0.9;
  vertical-align: -webkit-baseline-middle;
}

.sum_img {
  margin-top: 15px;
}

.pos_administrator {
  padding-left: 7px;
  padding-right: 7px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;

  background: #f05f23;
  border-radius: 2px;
  margin-right: 10px;
}
.pos_paying_member {
  padding-left: 7px;
  padding-right: 7px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;

  background: #0129fa;
  border-radius: 2px;
}

.pos_ded_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #f05f23;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: rgba(240, 95, 35, 0.15);
  border-radius: 2px;
  display: flex;
  justify-content: end;
}

.pos_pri_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0129fa;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: rgba(1, 41, 250, 0.1);
  border-radius: 2px;
  display: flex;
  justify-content: end;
}
