/* approval_screen = a_s  */
.a_s_centerized {
  text-align: center;
}

.a_s_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  text-align: center;
  align-items: center;
}

.a_s_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  color: #808080;
  text-align: center;
  align-items: center;
}
