.chatButton {
}

.rightbarchat .rce-mbox {
  background: #007aff !important;
  color: white !important;
}

.rightbarchat .rce-mbox-right-notch {
  fill: #007aff !important;
}

.rightbarchat .rce-mbox-time {
  color: white !important;
}

.leftbarchat .rce-mbox {
  background: #f2f2f7 !important;
  color: #2c2c2e !important;
}

.leftbarchat .rce-mbox-left-notch {
  fill: #f2f2f7 !important;
}

.msgTextArea {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.inputchatCss {
  background: #ffffff;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: inset 0px 1px 0px #e5e5ea;
}

.rightbarchat .rce-mbox-photo--img img {
  height: 200px;
}

.chatfirstdiv {
  background-color: rgba(0, 0, 0, 0.12);
}

.chatlabel1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  background: #f7b718;
  border-radius: 4.70588px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
}

.chatlabel2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  align-items: center;

  color: #000000;
}

.chatlabel3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #828282;
}

.meetingRoomp3 {
  display: flex;
  padding-left: 10px;
}

.chatFirstCard {
  position: fixed;
  background: #ffffff;

  height: 80vh;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}

.messagesCard {
  height: 73vh;
  border: none;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #e5e5ea;
}

.firstCenterDiv {
  text-align: center !important;
  position: sticky;
}
.parentDivOfChat {
  overflow: auto;
  /* height: 60vh; */
  height: 54vh;
  padding-left: 20px;
  padding-right: 20px;
}

.inputchatCss .input:focus {
  outline: none;
}

input[type="text"]:focus {
  outline: none;
}

.messageAreaField {
  border: none;
  height: 100px;
}
