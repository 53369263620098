.hideError {
  display: none;
}

.loginRow .loginForm .displayError {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ef4050;
}
.loginRow .loginForm .invalidSpan {
  color: #ef4050;
}
