.buy-now-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #0129fa;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  border: 1px solid rgba(1, 41, 250, 0.2);
  border-radius: 4px;
  padding: 10px;
}
