.faqContainer h3 {
  font-family: "Montserrat";
  color: #000000;
  font-size: 22px;
}

.faqContainer .faqCardContainer h4 {
  color: var(--official-black, #333);
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 700;
}

.faq-not-found .content-faqNotFound h3 {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.faq-not-found .content-faqNotFound p {
  color: var(--New-grey-description, #808080);
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.faq-not-found .notfoundRow {
  display: flex;
  justify-content: center;
}

.faqContainer .faqCardContainer pre {
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: var(--d-ark-text, #999);
}
.faqContainer .faqCardContainer p {
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: var(--d-ark-text, #999);
}
.faqContainer .faqCardContainer .descriptionContainer {
  margin-right: 35px;
}

.faqContainer .faqCardContainer {
  border-radius: 8px;
  background: #ffffff;
  border: none;
  text-align: justify;
}
