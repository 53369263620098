/* meeting room confirmed = mrc  */

.mrc_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.mrc_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #808080;
}

.mrc_l3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 71px;
}

.btnLink {
  text-decoration: none;
}

.mrc_l4 {
  /* display: flex;
  justify-content: center; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}
