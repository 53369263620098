/* what Happens = wh  */

.whatHappeningPage {
  margin-bottom: 30%;
}

.wh_card1 {
  filter: drop-shadow(0px 2.77469px 5.3241px rgba(0, 0, 0, 0.04));
  border-radius: 8px;
}

.feedCardBottom {
  display: flex;
  height: 100%;
  align-items: end;
  align-content: end;
  align-self: flex-end;
}

.feedFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
