.myTeamMainPage h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.myTeamCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}

.CreditContainer h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}

.myTeamCard h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.myTeamCard .POmyTeamSpan {
  background: rgba(1, 41, 250, 0.1);
  border-radius: 2px;
}

.POmyTeamSpan span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #0129fa;
}

.DDmyTeamSpan span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #f05f23;
}

.DDmyTeamSpan {
  background: rgba(240, 95, 35, 0.1);
  border-radius: 2px;
}

.myTeamCard {
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 20px;
  background: #ffffff;
  border-radius: 5px;
  border: none;
}
.teamManagementHeader span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #0129fa;
}
.myTeamMainPage h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.myTeamEditBtn {
  width: 66.36px;
  height: 30px !important;
  background: #0129fa;
  border-radius: 4px;
  border: none;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.AvtiveMembers .memberList {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.creditSwitch {
  display: flex;
}

.CreditCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}
.CreditCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.CreditCard {
  background: #e6e6e6;
  border-radius: 4px;
  border: none;
}

.changeAdminBtn {
  background: #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-right: 10px;
  border: none;
  height: 38px;
  width: 255px;
}
.changePayingBtn {
  background: #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  border: none;
  height: 38px;
  width: 255px;
}

.removeLink {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ef4050;
  text-decoration-line: underline;
}

.addMemberBtn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background: #000000;
  width: 25%;
  border-radius: 4px;
  border: none;
}

.newTeamForm h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.newTeamForm .required:after {
  content: " *";
  color: red;
}

.newMemberFields {
  background: #eeeeee;
  border-radius: 4px;
  border: none;
  height: 48px;
}

.newTeamConfirmBtn {
  background: #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
}

.spinnerLoader {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.resourceErrDis p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  font-size: 16px;
  color: #ef4050;
}
.resourceErr p {
  display: none;
}

@media only screen and (max-width: 900px) {
  .myTeamImg {
    display: none;
  }

  .addMemberBtn {
    width: 30%;
  }
}

@media only screen and (max-width: 600px) {
  .changeAdminBtn {
    font-size: 12px;
  }
  .changePayingBtn {
    font-size: 12px;
  }

  .addMemberBtn {
    width: 100%;
  }
}
