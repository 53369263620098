/* schdule meeting room = smr  */

.smr_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
.smr_h1_red {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: red;
}

.smr_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.smr_dur_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #999999;
  border: 0.911215px solid #c9c9c9;
  border-radius: 5.46729px;
  width: 96px;
  height: 42px;
  /* height: 100%; */
}

.smr_dur_h01 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  background: #000000;
  border-radius: 4px;
  width: 96px;
  height: 42px;
  /* height: 100%; */
}

.smr_yes_btn {
  border: 0.911215px solid #c9c9c9;
  color: black !important;
}

.smr_dateTime {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  min-height: "62px";
  height: "62px";
}

.itemContainer {
  width: 42;
  align-items: center;
  justify-content: center;
}

.timeline_hours {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: flex;
  align-items: center;
  background: #d8deff;
  /* height: 42px; */
  height: 100%;
  text-align: center;
}

.timeline_hours_not {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: flex;
  align-items: center;
  background: #f1f1f1;
  /* height: 42px; */
  height: 100%;
  text-align: center;
}
.timeline_minutes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  background: #d8deff;
  text-align: center;

  display: flex;
  align-items: center;
  /* height: 42px; */
  height: 100%;
  color: rgba(0, 0, 0, 0.5);
}

.timeline_minutes-not {
  background: #f1f1f1;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  text-align: center;

  display: flex;
  align-items: center;
  /* height: 42px; */
  /* height: 100%; */
  height: 40px;
  color: rgba(0, 0, 0, 0.5);
}

.timeline_parent {
  height: 42px;
  overflow: hidden;
  scroll-behavior: smooth
}

.timeline_parent_unavailable {
  /* background: #f1f1f1; */
  height: 100%;
  /* height: 42px; */
}
.timeline_child {
  scroll-behavior: smooth;
  display: flex !important;
  align-items: center !important;
  background: #d8deff;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100%;
  
}

.timeline_child ::-webkit-scrollbar {
  display: none;
}
.timeline_parent ::-webkit-scrollbar {
  display: none;
}

.avail_unavail_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: rgba(0, 0, 0, 0.5);
}

.dot_Unavail {
  height: 17.17px;
  width: 16.86px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.dot_Avail {
  height: 17.17px;
  width: 16.86px;
  background-color: #d8deff;
  border-radius: 50%;
  display: inline-block;
}

.timeline_format {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 7.5px;
  line-height: 9px;
  text-align: center;

  color: #000000;
}

.rdtPicker {
  width: 100%;
}

.selectedTimeBorderWrong {
  z-index: 9999;
  border-top: 1px solid #ef4050 !important;
  border-bottom: 1px solid #ef4050 !important;

  /* border-radius: 10px; */
  /* height: 40px; */
  height: 100%;
}
.first-box{
  border-left: 1px solid#0129FA !important;
}

.last-box{
  border-right: 1px solid#0129FA !important;
}
.first-box-red{
  border-left: 1px solid#ef4050 !important;
}

.last-box-red{
  border-right: 1px solid#ef4050 !important;
}

.selectedTimeBorder {
  border-top: 1px solid#0129FA !important;
  border-bottom: 1px solid#0129FA !important;
  
  
  height: 100%;
/* border: 1px solid#0129FA !important; */
  /* height: 42px; *
  /* border-radius: 10px; */
}

/* exp start */

.selectedTimeRightBorder {
  border-right: 1px solid#0129FA !important;
  height: 100%;
}
.selectedTimeLeftBorder {
  border-left: 1px solid#0129FA !important;
  height: 100%;
}

.selectedTimeRightBorderWrong {
  border-top: 1px solid #ef4050 !important;
  border-bottom: 1px solid #ef4050 !important;
  border-right: 1px solid #ef4050 !important;
  height: 100%;
}
.selectedTimeLeftBorderWrong {
  border-top: 1px solid #ef4050 !important;
  border-bottom: 1px solid #ef4050 !important;
  border-left: 1px solid #ef4050 !important;
  height: 100%;
}

/* exp end  */

.s_o_cc_Selected {
  cursor: pointer;
  background: #ffffff;
  /* box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09); */
  border-radius: 8px;

  border: 1px solid #0129fa;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.1);
}

.react-datetime-picker input {
  width: 900px !important;
}

.icon-box {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  /* background-color: none; */
  background-color: #ffffff;
  height: 36px;
  width: 36px;
  padding: 5px 6px 5px 6px;
  border-radius: 50px;
  float: right;
}

.icon-box a {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px 4px;
}

.icon-box a span {
  width: 20px;
  height: 20px;
}
