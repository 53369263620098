/* selectOffice = so */

.so_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}

.so_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
  color: #000000;
}

.so_total_users {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #000000;
  opacity: 0.9;
  vertical-align: -webkit-baseline-middle;
}

.s_o_cc {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin-bottom: 20px;
}
