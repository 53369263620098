.exploreBottomImgContainer {
  display: flex;
  flex-direction: column-reverse;
}

.settings-page {
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-page h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  /* float: ; */
  position: absolute;
  left: 85px;
  top: 40px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.setting-card {
  background: #ffffff;
  border-radius: 4px;
}
.cardText h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.cardText p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
