/* meeting room = m_rB */

.m_rB_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;

  color: #828282;
}

.m_rB_l3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
}

.m_rB_l4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_l5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_space {
  justify-content: space-between;
}

.m_rb_c1_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.9;
}
.m_rb_c1_l11 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: black;
  opacity: 0.9;
}

.m_rb_c1_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.m_rb_c1_l22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: black;
}

.mr_duration_bor {
  background: #0129fa;
  box-shadow: 0px 4.51646px 10.162px rgba(0, 0, 0, 0.16);
  border-radius: 0px 9px 0px 3.64486px;
  height: 32.03px;
  text-align: center;
  position: absolute;
  top: -1;
  right: 0;
  padding-top: 5px;
}
.mr_duration_bor1 {
  background: #f7b718;
  box-shadow: 0px 4.51646px 10.162px rgba(0, 0, 0, 0.16);
  border-radius: 0px 9px 0px 3.64486px;
  height: 32.03px;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 5px;
}

.mr_duration_Cancel {
  background: #ef4050;
  box-shadow: 0px 4.51646px 10.162px rgba(0, 0, 0, 0.16);
  border-radius: 0px 9px 0px 3.64486px;
  height: 32.03px;
  text-align: center;
  position: absolute;
  top: -1;
  right: 0;
  padding-top: 5px;
}

.mr_duration_pending {
  background: #f7b718;
  box-shadow: 0px 4.51646px 10.162px rgba(0, 0, 0, 0.16);
  border-radius: 0px 9px 0px 3.64486px;
  height: 32.03px;
  text-align: center;
  position: absolute;
  top: -1;
  right: 0;
  padding-top: 5px;
}
.mr_duration_confirmed {
  background: #35d7a1;
  box-shadow: 0px 4.51646px 10.162px rgba(0, 0, 0, 0.16);
  border-radius: 0px 9px 0px 3.64486px;
  height: 32.03px;
  text-align: center;
  position: absolute;
  top: -1;
  right: 0;
  padding-top: 5px;
}

.mr_coming_font {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: all-small-caps;
  color: #ffffff;
}

.mr_mettingRoomImg {
  float: right;
}

.marginless {
  display: block;
}

.mr_cal {
  margin-left: 12px;
  margin-top: -15%;
}
.mr_cal_sum {
  /* margin-top: -15%; */
}
.mr_cal1 {
  margin-top: -15%;
}

.mr_today_dur {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.mr_today_dur1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: black;
}

.mr_Time {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  /* margin-left: 25%; */
}

.mr_Time1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.s_o_cc1 {
  border: 1.5px solid #00a297;
  /* filter: drop-shadow(0px 6.77469px 20.3241px rgba(0, 0, 0, 0.13)); */
  border-radius: 13.5494px;
}
.s_o_cc6 {
  border: 1.5px solid black;
  /* filter: drop-shadow(0px 6.77469px 20.3241px rgba(0, 0, 0, 0.13)); */
  border-radius: 13.5494px;
}

.m_rb_c1 {
  background: #202020;
  border-radius: 8px;
  color: white;
}

.s_o_cc2 {
  /* background: rgba(0, 0, 0, 0.04); */
  filter: drop-shadow(0px 2.77469px 5.3241px rgba(0, 0, 0, 0.04));
  border-radius: 8px;
}

.mr_annouct_hr {
  width: 44px;
  border: 2px solid #f05f23;
  border-radius: 100px;
}

.mr_annouct_hr1 {
  width: 44px;
  border: 2px solid #0129fa;
  border-radius: 100px;
}

.mr_announcment1 {
  background: #f7b718;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
}

.imgCorners {
  border-radius: 10px !important;
}

.mr_announcment12 {
  background: #f05f23;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
}

.mr_announ_11 {
  font-family: "Montserrat";

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.mr_announcment_12 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: rgba(0, 0, 0, 0.6);
}

.mr_announcment_122 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.my-3 .disbaleVisitor {
  color: var(--official-black, #000000);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.my-3 .disbaleVisitor a {
  color: var(--official-black, #000000);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.disabled-card {
  cursor: not-allowed; /* Change the cursor to indicate that the card is not clickable */
  opacity: 0.3; /* Reduce the opacity to make the card appear visually disabled */
  pointer-events: none; /* Prevent any pointer events on the disabled card */
}

@media only screen and (max-width: 749px) {
  .kforhide {
    display: none;
  }

  .mr_cal_sm_space {
    margin-left: 15px;
  }
}
