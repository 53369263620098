/* // helpDesk = hd */

.hd_d1 {
  /* background: #ffffff; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.cardimgCentered {
  margin-top: 36px;
}

.hd_p1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.hd_p2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* New grey description */

  color: #808080;
}

.hd_p3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  color: #000000;
}

.hd_p4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #000000;
}

.hd_p5 {
  color: #f99b1c;
}
.hd_p55 {
  color: #35d7a1;
}

.d_sm_report_cr {
  border: 1px solid #000000;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 14px;
}
