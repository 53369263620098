.forgotEmail {
  background: rgba(202, 202, 202, 0.1);
  /* Official Grey */

  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 50px;
  margin-bottom: 20px;
}

.forgotinputField h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
