.memberNameHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.removeModelHeader {
  background: rgba(202, 202, 202, 0.5);
  border: none;
  border-radius: 4px;
  height: 48px;
}
