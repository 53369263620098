/* .navContainer {
  position: fixed;
  z-index: +1;
  background-color: #fafafa;
} */

.nav-btn .nav-item span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

/* // we will change it for nav start */

.nav-btn .d-sm-block1 .nav-link.active .tabDeskCss {
  color: #ebb542;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.nav-btn .d-sm-block2 .nav-link.active .tabDeskCss {
  color: #30b991;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.nav-btn .d-sm-block1 .nav-link.active {
  border-bottom: 5px solid #ebb542;
  border-radius: 4px;
}

.nav-btn .d-sm-block2 .nav-link.active {
  border-bottom: 5px solid #30b991;
  border-radius: 4px;
}

.nav-btn .nav-link.active {
  border-bottom: 5px solid #000000;
  border-radius: 4px;
}
/* // we will change it for nav end */

.nav-btn .nav-link.active span {
  color: #000000;
}
.invoicesContainer {
  padding-bottom: 200px;
}

.d-none {
  font-family: "Montserrat";
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block;
  /* line-height: 20px; */
}

.membershipInvoiceCard {
  background: #ffffff;

  /* Card Shadow */
  border: none;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
.membershipInvoiceCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.membershipInvoiceCard .membershipInvoiceCol h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.membershipInvoiceCard a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.membershipInvoiceCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.membershipInvoiceCard .membershipInvoiceCol h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}
.membershipInvoiceCard .membershipInvoiceCol h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ef4050;
}
.membershipInvoiceCard .membershipInvoiceCol h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #30b991;
}
.membershipInvoiceCard span {
  margin-left: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.activeStatusContainer {
  display: flex;
  align-items: center;
}

.activeStatus {
  display: inline-block;
  background: #35d7a1;
  border-radius: 100px;
  width: 14px;
  height: 14px;
}

.bottomImage {
  position: fixed;
  bottom: 0;
  width: 80%;
  /* opacity: 0.6; */
}

.statusDiv {
  text-align: right;
}

.invoiceDetailImg {
  position: fixed;
  bottom: 0;
  width: 35%;
}

.invoiceDetailPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.invoiceDetailPage h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.invoiceDetailPage h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0129fa;
}
.invoiceDetailPage p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}
.invoiceDetailPage h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.teamDetail h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.teamDetail p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-bottom: 3px;
}

.billingLeft {
  display: inline-block;
  background: #0129fa;
  border-radius: 100px;
  width: 5px;
  margin-top: 5px;
  margin-right: 10px;
  height: 5px;
}
.billingRight {
  margin-left: 10px;
  display: inline-block;
  background: #0129fa;
  margin-top: 5px;
  border-radius: 100px;
  width: 5px;
  height: 5px;
}

.teamDetail .billingPeriod {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 9px;
  color: #999999;
}

.invoiceDetailCard {
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px 4px 0px 0px;
}

.productsDetail h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.productsDetail h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}

.totalPayable {
  background: rgba(1, 41, 250, 0.2);
  border-radius: 0px 0px 4px 4px;
  border-top: none;
}

.totalPayable h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0129fa;
}

.totalPayable h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0129fa;
}

.cardRightText {
  text-align: right;
}

.uploadInvoicePage h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}
.uploadInvoiceCard {
  background: #ffffff;
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  border: none;
}
.uploadInvoiceCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.uploadInvoiceCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.accountPay h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.accountPay .accountPayCard h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #0129fa;
}
.accountPay .accountPayCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.accountPay .accountPayCard h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.accountPayCard {
  background: #ffffff;
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  border: none;
}

.commentArea h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
}

.textAreaField {
  height: 100px;
  background: #ffffff;
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}

.planChangeTextArea {
  height: 250px;
}
.sideCardView {
  width: 75%;
}

@media only screen and (max-width: 600px) {
  .numberMemberCard {
    margin-top: 20px;
  }

  .sideCardView {
    width: 100%;
  }
  .bottomImage {
    width: 100%;
    /* opacity: 0.6; */
  }
}
