.modelHeader {
  text-align: center;
}

.modelHeader h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000000;
}
.modelHeader p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}

.requestModelBtn {
  background: #000000;
  border-radius: 4px;
}

.fintechModel {
  border-radius: 4px;
}

.requestCancelModelBtn {
  background: #cacaca;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  margin-right: 10px;
}

.requestUpdateModelBtn {
  background: #000000;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  margin-left: 10px;
}
