/* daypass_meeting_room = dmr  */
.dmr_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.dmr_h2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dmr_h3 {
  opacity: 0.4;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dmr_h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #000000;
}

.dmr_h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* margin-top: 13px; */
  text-decoration-line: underline;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.dmr_h6 {
  margin-top: -15px;
}

.dmr_h7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
.dmr_h8 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;

  color: #000000;
}

.dmr_h9 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #808080;
}

.dmr_right {
  float: right;
}

.dmr_stats {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;

  color: #35d7a1;
  flex: none;
  order: 0;
  flex-grow: 0;

  background: rgba(53, 215, 161, 0.2);
  border-radius: 2px;
}

.dmr_statsad {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;

  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;

  background: #f7b718;
  border-radius: 2px;
}

.dmr_inv_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0129fa;
  text-align: left;
}

.dmr_inv_float_right {
  text-align: right;
}

.dmr_inv_h2 {
  font-family: "Montserrat";

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.dmr_inv_float_left {
  display: flex;
  text-align: left;
}

.dmr_inv_h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.dmr_inv_mail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #999999;
}

.dmr_inv_vat {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 17px; */

  color: #999999;
}

.dmr_btm {
  background: rgba(1, 41, 250, 0.15);
}

.dmr_inv_paid {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0129fa;
}
