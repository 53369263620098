.printingCreditPage h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
}
.printingCreditPage h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.printingCreditPage .addCreditCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.addCreditCard {
  background: #ffffff;
  box-shadow: 0px 4.82px 18.2243px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  border: none;
  height: 62px;
}

.printingCreditPage .addCreditCard span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.printingCreditPage .addCreditCard .addCreditBtn {
  background: rgba(1, 41, 250, 0.2);
  border-radius: 4px;
  border: none;
  color: #0129fa;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  --bs-btn-hover-bg: rgba(1, 41, 250, 0.2);
  --bs-btn-hover-color: #0129fa;
}

.add-member-field {
  background: #eeeeee;
  border-radius: 8px;
  border: none;
  height: 62px;
  padding: 15px;
}

.select-pkg-c1 {
  background: #ffffff;
  box-shadow: 0px 1.82243px 18.2243px rgba(0, 0, 0, 0.15);
  border-radius: 7.28972px;
}

.select-pkag-l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.select-pkag-l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #000000;
}

.select-pkag-l3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;

  color: #000000;
}

.select-pkag-l4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  color: #000000;
}

.select-pkag-l5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25.7143px;
  line-height: 31px;
  color: #000000;
}

.select-pkag-l6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14.2857px;
  line-height: 17px;

  color: #000000;
}

.select-pkag-l7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14.2857px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.5;
}

.select-pkag-l8 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22.8571px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Main */

  color: #0129fa;
}

.select-pkag-l9 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Official Black */

  color: #333333;
}

.select-pkag-l10 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22.8571px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.select-pkag-img {
  height: 110.12px;
  width: 88.38px;
}

.sum-l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.creditHistoryListcSS {
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  /* Card Shadow */
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.09);
}

.credit_h_p1 {
  color: var(--official-black, #333);
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  text-transform: uppercase;
}

.credit_h_p2 {
  color: var(--official-black, #333);
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.credit_h_p3 {
  color: #000;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.credit_h_p4 {
  color: #000;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  opacity: 0.5;
}
