/* schdule in progress = sip  */
.sip_team_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}

.sip_teams_bg {
  background-color: rgba(244, 244, 244, 0.5) !important;
}

.sip_invited_mem {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(1, 41, 250, 0.1);
  border-radius: 60px;
}

.dayPassCalenderStyle {
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  border: rgba(0, 0, 0, 0.175);
}

.smr_h2 .react-calendar__tile:disabled {
  color: #1010104d;
}

.smr_h2 .react-calendar__tile {
  color: #000000;
}

/* .react-calendar__month-view__days {
  color: #000000;
} */
.sip_invit_mem {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0129fa;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sip_other_invites {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
}

.sip_intvies_input {
  height: 62px;
  background: rgba(202, 202, 202, 0.5);
  opacity: 0.6;
  border-radius: 4px;
}

.sip_invites_addmore {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0129fa;
}

.sip_invity {
  background: rgba(1, 41, 250, 0.1);
  border-radius: 4px;
}

.c-avail_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.c-avail_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.c-avail_h3 {
  background: rgba(48, 185, 145, 0.1);
  border-radius: 4px;
  float: right;
}
.c-avail_h4 {
  color: #30b991;
  order: 0;
  flex-grow: 0;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
}

@media only screen and (max-width: 723px) {
  .sm_scr_shw {
    display: none;
  }
  .sm_top_margn {
    margin-top: 20px;
  }
}
