.sidebarheadings {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 20px;
}

.sideMenuLinks {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
}

.sidebarlogout {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* margin-left: 20px; */
  color: #ef4050;
}

.verticalNavIcon {
  margin-right: 25px;
  width: 20px;
}

.finTechIcon {
  margin-top: 33px;
}
.sideBarImg {
  display: flex;
  justify-content: center;
}
.side-Tab {
  margin-top: 60px;
}

.side-nav-icon {
  display: none;
}

.pro-sidebar {
  position: fixed;
  display: inline-block;
  vertical-align: top;
  width: 22%;
}

.activesidebar {
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  /* opacity: 0.5; */
  border-radius: 0px 5px 5px 0px;
}
.inactivesidebar {
  color: azure;
}

.daypass-side-n {
  display: none !important;
}

.vlHR {
  width: 80%;
}

.sideNav-bar {
  z-index: 999;
  width: 100%;

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 0px 20px 20px 0px;
}
.navlinkText h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.navlinkText p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ababab;
}

.sidebar-scroller {
  max-height: calc(95vh - 150px); /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.sideNavShow {
  /* border: #ef4050 solid; */

  background: #121212;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 0px 20px 20px 0px;
}

.divDIvider {
  padding-top: 10%;
}

@media only screen and (max-width: 1200px) {
  .sideNavShow {
    width: 100%;
  }
  .side-nav-icon {
    display: block;
    color: white;
  }
  .finTechIcon span {
    font-size: 13px;
  }

  .sideNavImg {
    width: 100%;
  }
}
@media only screen and (max-width: 1400px) {
  .navlinkText h5 {
    font-size: 12px;
  }
  .navlinkText p {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1600px) {
  .divDIvider {
    padding-top: 30%;
  }
}
@media only screen and (min-height: 800px) {
  .divDIvider {
    padding-top: 30%;
  }
}
