.f_div {
  /* background: #ffffff; */
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.del_a_p1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.del_a_p2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  /* New grey description */

  color: #808080;
}

.personalInfoData {
  padding-right: 20px !important;
}

.personalDetailTable Table thead tr th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Gray / Dark */
  color: #ababab;
  border: none;
}

.personalDetailTable Table tbody tr td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  color: #000000;
  border: none;
}

.manageSttingPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.del_a_p22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  /* Gray / Dark */

  color: #ababab;
}

.del_a_p3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  /* Decline */

  color: #ef4050;
}

.del_a_p33 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #000000;
}

.del_a_c2 {
  border: 1px solid #ef4050;
  /* Card Shadow */

  filter: drop-shadow(0px 1px 11px rgba(0, 0, 0, 0.09));
  border-radius: 4px;

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.del_a_c22 {
  border: 1px solid #000000;
  /* Card Shadow */

  filter: drop-shadow(0px 1px 11px rgba(0, 0, 0, 0.09));
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.del-a-check {
  /* background: #ffffff; */
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 4px;

  padding-top: 20px;
  padding-bottom: 20px;
}

.del_a_pp1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* New grey description */

  color: #808080;
}

.del_a_pp2 {
  width: 17px !important;
  height: 17px !important;
}

.del-a-check input[type="checkbox"] {
  /* height: 15px;
  width: 15px; */
  background-color: none;
  visibility: visible !important;
}

.del_a_pp5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ff0000;
}

.del_a_pp6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.del_a_pp7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
.del_warn_p1 {
  text-align: center;
  /* margin-left: 80px; */
  margin-left: 20%;
}
.del_warn_p2 {
  margin-left: 20%;
}

.del_warn_p3 {
  margin-top: 11vh !important;
}
.del_warn_p4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  color: #000000;
}

.del_warn_p5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  align-items: center;
  letter-spacing: -0.01em;

  color: #000000;
}

.del_warn_p6 {
  /* margin-left: 20px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* New grey description */

  color: #808080;
}

.del_warn_p7 {
  /* width: 8px;
  height: 8px;

  display: flex;
  background: #0129fa;
  border-radius: 100%; */

  color: #0129fa;
}

.del_a_p21 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 86% */

  display: flex;
  align-items: center;

  color: #999999;
}

.del_a_p22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  /* identical to box height, or 75% */

  display: flex;
  align-items: center;

  color: #000000;
}

.del_a_pp55 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* DArk Text */

  color: #999999;
}

.del_a_pp66 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* New grey description */

  color: #808080;
}

.del_card_disabled {
  opacity: 0.5;
  pointer-events: none !important;
}

.manageAccountContainer {
  margin-top: 4%;
}

.del_a_c22 span {
  color: var(--gray-dark, #ababab);
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
}

@media only screen and (min-width: 1440px) {
  .manageAccountContainer {
    margin-top: 25%;
  }
}
