/* user_roles = u_r  */

.u_r_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #0129fa;
}

.u_r_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */
  display: flex;
  align-items: center;
  color: #000000;
}

.u_r_row_color {
  background-color: #e6eaff;
  border-radius: 8px;
}

.u_r_hide_details {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Main */

  color: #0129fa;
}
