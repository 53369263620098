.maincontent {
  background-color: #f7f7f8;
}
.sidebar {
  min-height: 100vh;
}

.maincontainercss1 {
  background: #f7f7f8;
  padding: 0 0 50px 0;
  /* margin-top: 80px; */
  min-height: 100vh;
  /* margin-left: 10px; */
}

.sidebar-width {
  width: 20%;
}

.col-header {
  width: 80%;
}
.header-row {
  position: sticky;
  top: 0;
  /* width: 100%; */
  z-index: 1;
  /* box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%); */
}

@media only screen and (max-width: 1200px) {
  .nav-tab {
    display: none;
  }
  .col-header {
    width: 100%;
    /* display: none; */
  }
  .sidebar-width {
    width: 100%;
  }
}
