.t_d_card {
  border-radius: 4px;
  background: #fff;

  /* Card Shadow */
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.09);
}

.t_d_p1 {
  color: #000;
  font-size: 12px;
  font-family: "Montserrat";
}

.t_d_p2 {
  color: #000;
  font-size: 12px;
  font-family: "Montserrat";
  opacity: 0.5;
}

.t_d_p3 {
  color: var(--decline, #ef4050);
  font-size: 12px;
  font-family: "Montserrat";
  background-color: #ef40501a;
}

.t_d_p4 {
  color: #000;
  font-size: 16px;
  font-family: "Montserrat";
}

.t_d_p5 {
  color: var(--d-ark-text, #999);
  font-size: 12px;
  font-family: "Montserrat";
}

.t_d_p6 {
  color: #000;
  font-size: 22px;
  font-family: Montserrat;
}
