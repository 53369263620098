/* selectDuration = sd */

.sd_sideimg {
  float: right;
}

.sd_input {
  /* display: flex; */
  /* align-items: center; */
  padding: 10px;
  background: #f5f5f5;
  /* height: 53px; */
  height: 62px;
  border: 1px solid #cacaca;
  border-radius: 4px;
  width: 100%;
}

.sd_input_Active {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  background: #f5f5f5;
  /* height: 53px; */
  height: 62px;
  border: 1px solid #f05f23;
  border-radius: 4px;
}
.sd_input1 {
  /* padding: 10px; */
  padding-top: 5px;
  padding-left: 10px;
  background: #f5f5f5;
  /* height: 53px; */
  height: 62px;

  border: 1px solid #f05f23;
  border-radius: 4px;
}

.sd_btn_font {
  align-items: center !important;
  color: black;
  /* text-align: center; */
  float: center;
}

.sd_labelName {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* Official Black */
}

.sd_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
.sd_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #000000;
}

.sd_administrator {
  padding: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  background: #f05f23;
  border-radius: 2px;
}
.team_administrator {
  padding: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  background: #f05f23;
  border-radius: 2px;
  margin-left: 13px;
}

.sd_payingMember {
  margin-left: 13px;
  padding: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  color: #ffffff;
  background: #0129fa;
  border-radius: 2px;
}
.sd_administrator_paying {
  display: block;
}

/* //duration date picker previous and next button  */
.react-datepicker__navigation {
  display: none !important;
}

.inputRadioGroup {
  display: flex;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 4px;
}

.checkLabel {
  display: flex;
  width: 100%;
  /* flex: auto; */
  vertical-align: middle;
  align-items: center;
  justify-content: left;
  text-align: center;
  cursor: pointer;
  background-color: none;
  height: 62px !important;
  /* color: #333333; */
  padding: 5px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
  border: 1px solid #cacaca;
  border-radius: 4px;
}

.inputRadioGroup input[type="checkbox"]:checked + label {
  border: 1px solid #f05f23;

  background-image: url("../../../../../../images/selectedMembers.svg");
  /* background: url("../../../../../images/selectedMembers.svg") no-repeat 80% 50%; */
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
  /* background-color: rgba(1, 41, 250, 0.1);
  color: #000000; */
}

input[type="checkbox"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
  background-color: none;
}

.inputRadioGroup input[type="radio"]:checked + label {
  border: 1px solid #f05f23;

  background-image: url("../../../../../../images/selectedMembers.svg");
  /* background: url("../../../../../images/selectedMembers.svg") no-repeat 80% 50%; */
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
  /* background-color: rgba(1, 41, 250, 0.1);
  color: #000000; */
}

input[type="radio"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
  background-color: none;
}
