/* privateOffice = pv */

.pv_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.pv_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
/* d-inline-block text-truncate */

.noteDIv {
  display: flex;
  align-items: center;
  height: 100%;
}

.pv_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  /* DArk Text */

  color: #999999;
}
.pv_main_h {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}
.noteDIv h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #f05f23;
}

.pv_btm_h {
  text-align: justify;
  text-justify: inter-word;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.timePicker {
  display: none !important;
}

.react-ios-time-picker-popup {
  left: 1px !important;
}

.link_line {
  text-decoration: none;
}
.pv_btm_card {
  background: #ffffff;
  border: 0.911215px solid #f05f23;
  border-radius: 7.28972px;
}

.pv_pic {
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  /* position: fixed; */
  position: relative;

  width: 100%;
  bottom: 0px;
}

.ImageContainer {
  /* height: 100%; 
  */

  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
}
/* pvn = private office new design  */

.pvn_card {
  cursor: pointer;
  background: #000000;
  border-radius: 8px;
}
.pvn_space {
  justify-content: space-between;
  display: flex;
}

.pvn_card_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
}

.pvn_card_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  display: inline-flex;
  align-items: center;
  color: #f05f23;
  justify-content: flex-end;
}

.pvn_card_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
}
.pvn_card_h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.pvn_card_h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #999999;
}

.pvn_card_h6 {
}

.pvn_tick_m {
  margin-right: 10px;
}
.pv_note {
  display: flex;
  justify-content: center;
  align-items: center;
}
