/* meeting room = m_rB */

.m_rB_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;

  color: #828282;
}

.m_rB_l3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
}

.m_rB_l4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_l5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.m_rB_space {
  justify-content: space-between;
}

.m_rb_c1_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.9;
}
.m_rb_c1_l11 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: black;
  opacity: 0.9;
}

.m_rb_c1_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #ffffff;
}

.m_rb_c1_l22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: black;
}

.mr_coming_font {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: all-small-caps;
  color: #ffffff;
}

.mr_mettingRoomImg {
  float: right;
}

.marginless {
  display: block;
}
.mr_cal {
  margin-left: 12px;
  margin-top: -15%;
}

.mr_cal1 {
  margin-top: -15%;
}

.mr_today_dur {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.mr_today_dur1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: black;
}

.mr_Time {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  /* margin-left: 25%; */
}

.mr_Time1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.m_rb_c1 {
  background: #202020;
  border-radius: 8px;
  color: white;
}

.mr_annouct_hr {
  width: 44px;
  border: 2px solid #f05f23;
  border-radius: 100px;
}

.mr_annouct_hr1 {
  width: 44px;
  border: 2px solid #0129fa;
  border-radius: 100px;
}

.mr_announcment1 {
  background: #f7b718;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
}

.mr_announcment12 {
  background: #f05f23;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
}

.mr_announ_11 {
  font-family: "Montserrat";

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.mr_announcment_12 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: rgba(0, 0, 0, 0.6);
}

.mr_announcment_122 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

/* .notifyMembership {
  display: flex;
  flex-direction: column;
} */

.notifyMembershipExpired {
  border-radius: 8px;
  background: rgba(250, 4, 4, 0.1);
  border: 1px solid rgba(250, 4, 4, 0.2);
}
.notifyMembershipStart {
  border-radius: 8px;
  background: rgba(255, 188, 2, 0.1);
  border: 1px solid rgba(255, 188, 2, 0.2);
}

.notifyMembership .notifyMembershipContainer h1 {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.notifyMembership .notifyMembershipContainer span {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
