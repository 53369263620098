.feedDetailPage .feedHeading h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #333333;
}

.feedDetailPage {
  margin-bottom: 30%;
}
.feedDetailPage .feedHeading p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}

.feedDetailPage .feedHeading .detailFeedBackBtn {
  background-color: rgba(1, 41, 250, 0.1);
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.feedDetailPage .feedTimeFlex .redClock {
  background-color: rgba(240, 95, 35, 0.1);
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.feedTimeFlex {
  display: flex;
  float: right;
}

.feedDetailCard {
  border-radius: 4px;
  border: none;
}

.feedDetailCard h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
}
.feedDetailCard span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #cacaca;
}

.feedDetailCard .feedDescriptionRow p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  color: #999999;
}
