.headerBG {
  background-color: #000000;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerLogo {
  width: 120px;
  padding: 15px;
}

/* .headerProgressBar{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;

} */

.step-number {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px;
  text-align: center !important;
  /* line-height: 20px; */
  /* border: 2px solid blue; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;

  color: #ffffff;

  border-radius: 50%;
  position: relative;
  background-color: #474747;

  @media (max-width: 991.98px) {
    display: block;
    margin: 0 auto 8px !important;
  }
}

.sidebtn {
  display: none;
}

.step-number-active {
  display: inline-block;
  padding: 5px;
  width: 28px;
  height: 28px;
  line-height: 20px;
  /* border: 2px solid blue; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;

  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  position: relative;
  background-color: #f05f23;

  @media (max-width: 991.98px) {
    display: block;
    margin: 0 auto 8px !important;
  }
}
/* .nav-link :active {
  background-color: transparent;
  color: pink;
} */

/* .step-number :active {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 34px;
  
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  position: relative;
  background-color: green;
} */

/* .nav-link step-title {
  display: block;
  margin-top: 8px;

  @media (max-width: 575.98px) {
    display: none;
  }
} */

.prog-line {
  /* border-left: 2px dashed #00bcae; */
  align-items: center !important;
  /* justify-content: center; */
  border: 1px solid;
  /* width: 100% !important; */
  width: 73.04px;
  background: #cacaca;
  /* opacity: 0.35; */

  height: 0.5px;
  /* height: 10px; */
  /* margin-left: 20px; */

  /* content: "";

  background-color: red;
  position: absolute;
  top: 13px;
  height: 20px;
  margin-left: 20px;

  background: "lime";
  color: "lime";
  border-color: "lime";
  height: "3px"; */
}

/* .twitter-bs-wizard twitter-bs-wizard-nav {
  position: relative;
}
.twitter-bs-wizard :before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: grey;
  position: absolute;
  left: 0;
  top: 26px;
} */

/* //jdhfsd */
/* 
.twitter-bs-wizard {
  .twitter-bs-wizard-nav {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $gray-300;
      position: absolute;
      left: 0;
      top: 26px;
    }

   

    .nav-link {
      .step-title {
        display: block;
        margin-top: 8px;
        font-weight: $font-weight-bold;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      &.active {
        background-color: transparent;
        color: $gray-700;

        .step-number {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  .twitter-bs-wizard-pager-link {
    padding-top: 24px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      display: inline-block;

      a {
        display: inline-block;
        padding: 0.47rem 0.75rem;
        background-color: $primary;
        color: $white;
        border-radius: 0.25rem;
      }

      &.disabled {
        a {
          cursor: not-allowed;
          background-color: lighten($primary, 8%);
        }
      }

      &.next {
        float: right;
      }
    }
  }
}

.twitter-bs-wizard-tab-content {
  padding-top: 24px;
  min-height: 262px;
} */

.p-b-center {
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .sidebtn {
    display: block;
    /* background-color: #cacaca; */
  }
}

@media only screen and (max-width: 600px) {
  .headerBG .progressBarDisplayCol .progressBarDisplay {
    display: none !important;
  }
}
