/* schdule in progress = sip  */
.sip_team_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}

.sip_teams_bg {
  background-color: rgba(244, 244, 244, 0.5) !important;
}

.sip_invited_mem {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(1, 41, 250, 0.1);
  border-radius: 60px;
}

.sip_invit_mem {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0129fa;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sip_other_invites {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
}

.sip_intvies_input {
  height: 62px;
  background: rgba(202, 202, 202, 0.5);
  opacity: 0.6;
  border-radius: 4px;
}

.sip_invites_addmore {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0129fa;
}

.sip_invity {
  background: rgba(1, 41, 250, 0.1);
  border-radius: 4px;
}

.c-avail_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.c-avail_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.c-avail_h3 {
  background: rgba(48, 185, 145, 0.1);
  border-radius: 4px;
  float: right;
}
.c-avail_h4 {
  color: #30b991;
  order: 0;
  flex-grow: 0;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
}

.c-avail_h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.c-avail_h6 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
}

.c-avail_h7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.c-avail_h8 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0129fa;
}

.c-avail_h9 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.c-avail_h10 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Main */

  color: #0129fa;
}

.c-avail_date_space {
  display: flex;
  justify-content: space-between;
}

.c-avail_Dates {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
/* // payment day = p_day  */
.p_day_card {
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
}

.p_day_l1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
}

.p_day_l2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.p_day_l3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0129fa;
}

.p_day_l4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ef4050;
  flex: none;
  order: 0;
  flex-grow: 0;

  background: rgba(239, 64, 80, 0.1);
  border-radius: 4px;
  justify-content: center;
}

.p_day_l5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.p_day_l6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  /* New grey description */

  color: #808080;
}

.p_day_l7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
}

.p_day_l8 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: black;
}

.p_day_l9 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.p_day_l10 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #000000;
}

.p_day_img_border {
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 7.28972px;
}
