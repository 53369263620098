.noti_1 {
  border-radius: 8px;
  background: #ddddddc7;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  width: 100%;
}

.noti_logo {
  height: 60px;
  width: 60px;
}

.noti_p2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

.noti_3 {
  color: var(--new-grey-description, #808080);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.noti_4 {
  color: var(--d-ark-text, #999);
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: end !important;
  align-items: flex-end !important;
}

.noti_5 {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notification-dot {
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  top: 24px;
}
