.settingPageCard {
  background: #ffffff;
  /* Card Shadow */

  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  border: none;
}

.settingPage .settingPageCard h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.settingPage .settingPageCard p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ababab;
}
.settingPage h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
